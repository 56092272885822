import Service from "../Service";
import Vue from "vue";
const resource = "/ReceptionFresh/";

export default {

    exportExcel(obj, requestID) {
        return Service.post(resource + "exportExcel", obj, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: { requestID: requestID },
        });
    },

    GetReportHeavy(obj, requestID) {
        return Service.post(resource + "GetReportHeavy", obj, {
            params: { requestID: requestID },
        });
    },

    GetTransport(obj, requestID) {
        return Service.post(resource + "GetTransport", obj, {
            params: { requestID: requestID },
        });
    },

    GetGang(obj, requestID) {

        return Service.post(resource + "GetGang", obj, {
            params: { requestID: requestID },
        });
    },
    GetDriver(AdvPlaqueVehicle = '', requestID) {
        /*  return Service.post(resource + "GetDriver", "", {
             params: { AdvPlaqueVehicle: AdvPlaqueVehicle, requestID: requestID },
         }); */
        return Vue.prototype.$http.defaults.baseURL + resource + "GetDriver";
    },

    //Copiado de HarvestSchedule para probar
    GetLot(PrdID = 0, fagID = 0, TypeLot = 0, search = '', requestid) {
        return Service.post("HarvestSchedule/listLote", {}, {
            params: { PrdID: PrdID, FagID: fagID, indicador: TypeLot, Search: search, RequestID: requestid }
        });
    },
    //Copiado de HarvestSchedule para probar
    GetAgriculturalFarm(prdID = 0, search = '', requestid) {
        return Service.post("HarvestSchedule/listAgriculturalFarm", {}, {
            params: { PrdID: prdID, Search: search, RequestID: requestid },
        });
    },

    pagination(parameters, requestID) {
        //console.log("sdfsdfsd", parameters);
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },

    save(obj, requestID) {
        return Service.post(resource + "SaveReception", obj, {
            params: { requestID: requestID },
        });
    },

    saveDeriveFrozen(obj, requestID) {
        return Service.post(resource + "saveDeriveFrozen", obj, {
            params: { requestID: requestID },
        });
    },


    getAnalysisGuide(obj, requestID) {

        return Service.post(resource + "getAnalysisGuide", obj, {
            params: { requestID: requestID },
        });
    },
    PrfEnabledRcfState(obj, requestID) {

        return Service.post(resource + "PrfEnabledRcfState", obj, {
            params: { requestID: requestID },
        });
    },
    exportExcelGuideAnalysis(obj, requestID) {
        return Service.post(resource + "exportExcelGuideAnalysis", obj, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: { requestID: requestID },
        });
    },

    GetKgReception(obj, requestID) {

        return Service.post(resource + "GetKgReception", obj, {
            params: { requestID: requestID },
        });
    },

    TranferLPFresh(obj, requestID) {
        return Service.post(resource + "TranferLPFresh", obj, {
            params: { requestID: requestID },
        });
    },

    /********************************* PARA CONGELADO *****************************************/

    GetKgReceptionFrozen(obj, requestID) {

        return Service.post(resource + "GetKgReceptionFrozen", obj, {
            params: { requestID: requestID },
        });
    },

    syncLot(obj, requestID) {

        return Service.post(resource + "syncLot", obj, {
            params: { requestID: requestID },
        });
    },

    /* LoadDestiny() {
        return Vue.prototype.$http.defaults.baseURL + resource + "GetDestiny";
    },

    SearchFundMaquilador() {
        return Vue.prototype.$http.defaults.baseURL + resource + "GetFundMaquilador";
    }, */



    /* GetRecord(cHarvestNumberProgramming, requestID) {
        return Service.post(resource + "GetRecord", cHarvestNumberProgramming, {
            params: { requestID: requestID },
        });
    }, */

    /* GetGang(parameters, requestID) {
        //console.log("sdfsdfsd", parameters);
        return Service.post(resource + "GetGang", parameters, {
            params: { requestID: requestID },
        });
    }, */



};